@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: pxToRem(32) pxToRem(16);
  min-height: 45vh;
  box-sizing: border-box;
  background: var(--for-neu-tie2);
  &.fullHeight {
    min-height: 100vh;
  }
}

.content,
.button {
  max-width: pxToRem(768);
}

.content {
  padding: pxToRem(36) 0;

  :global(.title + .message) {
    margin-top: pxToRem(16);
  }
}

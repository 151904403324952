@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;
@use 'src/theme/spacings.module' as *;

.content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  &.galleryContent {
    position: relative;
    height: 100vh;
  }
}

.modalContainer:global(.modal) {
  margin: 0;
  height: 100dvh;
  width: 100%;
  max-height: 100dvh;
  max-width: 100%;
  border-radius: 0;

  :global(.modalHeader) {
    position: absolute;
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
  }

  :global(.modalContent) {
    max-height: 100%;
  }

  :global(.modalClose) {
    background-color: $color-uiPrimary-025;
    width: pxToRem(40);
    height: pxToRem(40);
    border-radius: pxToRem(8);
    border: pxToRem(1) solid $color-uiPrimary-400;
    top: 0;
    right: pxToRem(8);
    z-index: 999;
  }

  :global(.modalContentMain) {
    padding: 0;
    border: 0;
  }

  &.hotspotActive {
    :global(.modalClose) {
      visibility: hidden;
    }
  }
}

.content {
  width: 100%;
  height: 100%;
}

.contentView {
  display: none;
  height: 100%;
  width: 100%;

  &.active {
    display: block;
  }
}

.navigator {
  position: fixed;
  left: pxToRem(8);
  right: pxToRem(8);
  bottom: pxToRem(8);
}

.keyFeatureController {
  position: absolute;
  left: pxToRem(24);
  top: pxToRem(16);
}

.keyFeatureBox {
  position: absolute;
  top: auto;
  left: pxToRem(8);
  right: pxToRem(8);
  bottom: pxToRem(8);
  max-height: pxToRem(404);
}

@include md {
  .content {
    &.galleryContent {
      position: fixed;
    }
  }
  .modalContainer {
    :global(.modalClose) {
      width: pxToRem(56);
      height: pxToRem(56);
    }
  }

  .navigator {
    top: pxToRem(24);
    bottom: auto;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
  }
  .keyFeatureController {
    top: auto;
    bottom: pxToRem(24);
    left: 50%;
    transform: translateX(-50%);
  }
  .keyFeatureBox {
    position: absolute;
    top: 50%;
    left: auto;
    right: pxToRem(24);
    bottom: auto;
    transform: translateY(-50%);
    max-width: pxToRem(142);
  }
}

// For landscape mode
@include sml {
  .content.galleryContent {
    position: fixed;
  }
  .navigator {
    width: calc(100% - pxToRem(32));
    position: absolute;
    top: auto;
    left: pxToRem(8);
    bottom: pxToRem(8);
    right: auto;
    transform: translateX(0);

    .navigatorRoot {
      width: 50%;
    }
  }
  .keyFeatureController {
    position: absolute;
    left: auto;
    top: auto;
    bottom: pxToRem(8);
    right: pxToRem(8);
  }
  .keyFeatureBox {
    position: absolute;
    top: pxToRem(8);
    left: pxToRem(8);
    right: auto;
    bottom: pxToRem(8);
    max-width: pxToRem(280);
  }
}

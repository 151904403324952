@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

$inputTopPadding: pxToRem(16);
$inputTopPaddingSmall: pxToRem(17);
$inputBottomPaddingSmall: pxToRem(15);
$inputHorizontalPadding: pxToRem(20);
$labelTransition: ease-in-out 200ms;
$inputBottomMargin: pxToRem(16);

.root {
  display: block;

  // Display the label's chevron
  &:global(.selectWrapper) {
    .inputWrapper:after {
      content: '';
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      border-bottom: 1px solid currentColor;
      border-left: 1px solid currentColor;
      border-bottom-left-radius: 2px;
      transform: rotate(-45deg) translate(0%, 0%);
      transform-origin: center center;
      transition: transform ease-in-out 200ms;
      position: absolute;
      top: calc(50% - #{pxToRem(7)});
      right: pxToRem(16);
      pointer-events: none;
    }
  }
}

.root,
.inputPinWrapper {
  position: relative;
  margin-top: 0;
  margin-bottom: $inputBottomMargin;
}

:global(.tradeInTabWrapper) .root,
:global(.tradeInTabWrapper) .inputPinWrapper {
  margin-bottom: pxToRem(0); /* Override the margin-bottom */
}

:global(.labelWrapper) {
  &.withIconLeft {
    input,
    textarea,
    select {
      padding: $inputTopPadding pxToRem(36) 0;
    }

    .label {
      transform: translateY(pxToRem(-8)) scale(0.8);
    }
  }
}

.wrapper {
  &:not(.disabled) {
    cursor: pointer;

    &.error {
      .inputWrapper {
        box-shadow:
          0 0 0 pxToRem(2) var(--bor-err-bas),
          var(--elevation-1dp);
      }
    }

    &:hover {
      .inputWrapper {
        box-shadow:
          0 0 0 pxToRem(2) var(--bor-pri-bas),
          var(--elevation-1dp);
      }
    }
    &:focus-within {
      .inputWrapper {
        box-shadow:
          0 0 0 pxToRem(4) var(--bor-pri-bas),
          var(--elevation-1dp);
      }
    }
  }

  &.disabled {
    color: var(--tex-def-tie3);
    .inputWrapper {
      box-shadow:
        0 0 0 pxToRem(1) var(--bor-neu-dis),
        var(--elevation-1dp);
      background: var(--for-neu-dis);
    }
    .label {
      color: inherit;
    }
  }

  &.noLabel {
    input,
    select {
      padding-top: 0;
      padding-bottom: 0;
      &::placeholder {
        opacity: 1;
      }
    }
  }

  &:has(span.label) {
    select {
      padding: $inputTopPadding pxToRem(40) 0 $inputHorizontalPadding;
    }
  }

  &.small {
    min-height: pxToRem(56);
  }

  &.large {
    min-height: pxToRem(86);
  }

  // Reduce the size of the label
  &:not(.labelOutside) {
    &:focus-within,
    &.filled,
    &:has(input:not(:placeholder-shown)),
    &:has(select option:checked:not([value=''])) {
      .label {
        transform: translateY(pxToRem(-8)) scale(0.8);
      }

      option {
        color: var(--tex-def-tie1);
        cursor: pointer;
        padding: pxToRem(12) pxToRem(16);
      }
    }
  }

  // Variant for label outside
  &.labelOutside {
    .label {
      display: block;
      position: relative;
      top: unset;
      left: unset;
      color: var(--tex-def-tie2);
      font-weight: 800;
      @include fontSize(17, 24);
      margin-bottom: pxToRem(8);
      margin-left: pxToRem(2);
      transform: none;
    }

    .inputWrapper {
      min-height: pxToRem(54);

      select,
      textarea,
      input {
        &::placeholder {
          opacity: 1;
        }
      }

      select,
      input {
        padding-top: 0;
      }
    }
  }
}

.inputWrapper,
.inputPin {
  @include fontSize(17, 27);
  display: block;
  position: relative;
  box-sizing: border-box;
  min-height: pxToRem(60);
  border-radius: var(--cor_rad-xs);
  border: none;
  box-shadow:
    0 0 0 pxToRem(1) var(--bor-neu-bas),
    var(--elevation-1dp);
  transition: all ease-in-out 100ms;
  color: var(--tex-def-tie1);
  background: var(--for-neu-tie1);
  isolation: isolate;
  overflow: hidden;
  outline: none;

  .iconLeft {
    position: absolute;
    left: pxToRem(20);
    top: pxToRem(26);
    color: rgba(0, 0, 0, 0.54);
    z-index: 1;
  }

  input,
  textarea,
  select {
    font: inherit;
    color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: $inputTopPadding $inputHorizontalPadding 0;
    margin: 0;
    border: none;
    box-sizing: border-box;
    appearance: none;
    background: transparent;

    &::placeholder {
      opacity: 0;
      transition: opacity $labelTransition;
      color: var(--tex-def-tie3);
    }

    &:focus-visible {
      outline: none;

      &::placeholder {
        opacity: 1;
      }
    }
  }

  select {
    padding: $inputTopPaddingSmall $inputHorizontalPadding $inputBottomPaddingSmall;
  }

  textarea {
    resize: vertical;
    width: calc(100% - pxToRem(8));
    min-height: pxToRem(60);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }

  // gain removal of the arrow for lower IE versions
  select::-ms-expand {
    display: none;
  }
}

.label {
  @include fontSize(17, 27);
  font-weight: 800;
  color: var(--tex-def-tie2);
  position: absolute;
  top: pxToRem(16);
  left: $inputHorizontalPadding;
  pointer-events: none;
  transform-origin: top left;
  transition: transform $labelTransition;
  z-index: 1;

  + .subLabel {
    margin-top: pxToRem(-4);
  }
}

.subLabel {
  @include fontSize(15, 20);
  font-weight: normal;
  display: block;
  margin-top: pxToRem(4);
  margin-bottom: pxToRem(8);

  ul,
  ol {
    margin: 0 0 pxToRem(8);
    padding-left: pxToRem(20);
  }
}

.note {
  @include fontSize(14, 18);
  color: var(--tex-def-tie3);
  display: block;
  margin-top: pxToRem(4);

  a {
    color: var(--tex-def-tie1);
  }

  &.error {
    color: var(--tex-err-tie1);

    a {
      color: $color-sentimental-error-800;
    }
  }
}

/*************************************************
 * Custom styles for the FieldWrapperOptionsGroup
 *************************************************/

.rootOptionsGroup {
  border: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: pxToRem(8);
  margin-bottom: $inputBottomMargin;
}

.rootOptionsGroup,
.labelOptionsGroup {
  padding: 0;
}

.labelOptionsGroup {
  display: block;
  @include fontSize(17, 24);
  color: var(--tex-def-tie2);
  font-weight: 800;
  margin: 0 0 pxToRem(8) pxToRem(2);
  width: 100%;
}

.rootOptionsGroup2Columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: pxToRem(16);

  .labelOptionsGroup,
  :global(.note) {
    grid-column: 1/-1;
  }
}

@include md {
  .rootOptionsGroup2ColumnsMdOnly {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: pxToRem(16);

    .labelOptionsGroup,
    :global(.note) {
      grid-column: 1/-1;
    }
  }
}

/*************************************************
 * Custom styles for the InputPassword
 *************************************************/
.inputPasswordWrapper {
  position: relative;

  :global(input) {
    padding-right: pxToRem(56);
  }

  .inputPasswordButton {
    position: absolute;
    right: pxToRem(8);
    top: pxToRem(11);
  }
}

/*************************************************
 * Custom styles for the PinInput
 *************************************************/

.inputPinWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: pxToRem(8);
}

.inputPin {
  max-width: pxToRem(60);
  text-align: center;
}

@include sm {
  .inputWrapper {
    min-width: pxToRem(240);
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.autocompleteContainer {
  position: relative;
}

.popper {
  top: pxToRem(60);
  :global {
    .MuiAutocomplete-paper {
      background: var(--bac-tie1);
      border-radius: var(--cor_rad-xs);
      box-shadow:
        0 0 0 rgba(0, 0, 0, 0.04),
        0 2px 8px rgba(0, 0, 0, 0.16);

      .MuiAutocomplete-listbox {
        padding: pxToRem(8);
      }

      .MuiAutocomplete-option {
        padding: pxToRem(10) pxToRem(16);
        border-radius: pxToRem(6);
        margin: 0;

        &:hover,
        &[data-focus='true'] {
          background-color: $color-uiPrimary-100;
        }

        &:not(:first-child) {
          margin-top: pxToRem(4);
        }
      }
    }
  }
}

.formWrapper {
  :global {
    .MuiFormHelperText-root a {
      font-weight: 800;
      color: rgba(21, 13, 44, 0.88);
      text-decoration: underline;
    }

    .MuiFormHelperText-root.Mui-error p {
      color: var(--tex-err-tie1);
    }
  }
  &.withHelperText {
    margin-bottom: pxToRem(16);
  }
}

.itemOption {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @include fontSize(17, 24);
  color: var(--tex-def-tie1);

  mark {
    background: transparent;
    font-weight: 800;
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'suburb suburb'
    'postcode state';
  column-gap: pxToRem(14);
}

.suburb {
  grid-area: suburb;
}
.postcode {
  grid-area: postcode;
}
.state {
  grid-area: state;
}

.contactButton {
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  @include fontSize(14, 18);
  color: var(--tex-def-tie1);
  text-decoration: underline;
  font-weight: 800;
  cursor: pointer;
}

.locationInput {
  height: auto;
  :global {
    .MuiInputBase-input.MuiInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused {
      padding-left: pxToRem(20);
      padding-top: pxToRem(28);
      padding-right: pxToRem(16);
    }
    .MuiAutocomplete-endAdornment {
      right: pxToRem(16);
    }
  }
}

@include sm {
  .formWrapper {
    .popper {
      :global {
        .MuiAutocomplete-paper {
          width: 100%;
        }
      }
    }
  }
}

@include mdp {
  .row {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: 'suburb postcode state';
    gap: pxToRem(14);
  }
}

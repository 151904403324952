@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.root {
  &.hidden {
    &:global.MuiFormControl-root {
      display: none;
    }
  }

  &:global.MuiFormControl-root {
    margin: pxToRem(8) 0;
    @include fontSize(17, 24);
    display: block;
  }

  :global {
    .MuiFormLabel-root,
    .Mui-error.MuiFormLabel-root {
      color: var(--tex-def-tie2);
      font-weight: 800;
      font-size: inherit;
      line-height: inherit;

      &.Mui-disabled {
        color: var(--tex-def-tie3);
      }

      &.Mui-focused {
        color: var(--tex-def-tie2) !important;
      }
    }

    .MuiIconButton-colorPrimary {
      color: var(--tex-def-tie2);
    }

    .MuiInputLabel-root {
      transform: translate(pxToRem(22), pxToRem(25)) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(pxToRem(22), pxToRem(15)) scale(0.82);
      }
    }

    .MuiInputAdornment-positionStart {
      position: relative;
      left: pxToRem(20);
    }

    .MuiInputAdornment-positionEnd {
      position: relative;
      right: pxToRem(20);
    }

    .MuiInputBase-root {
      background: var(--bac-tie1);
      border: pxToRem(1) solid var(--bor-neu-bas);
      border-radius: var(--cor_rad-xs);
      box-shadow: var(--elevation-1dp);
      transition: all ease-in-out 100ms;
      min-height: pxToRem(56);
      margin: 0;
      width: 100%;
      overflow: hidden;
      display: flex;

      &.Mui-error {
        border-color: $color-sentimental-error-600;
      }

      &:not(.Mui-disabled):not(.Mui-focused):hover,
      &:not(.Mui-disabled):not(.Mui-focused).Mui-error:hover {
        border-color: var(--bor-pri-bas);
        box-shadow: 0 0 0 pxToRem(1) var(--bor-pri-bas);
        background: var(--bac-tie1);
      }

      &.Mui-focused,
      &.Mui-error.Mui-focused {
        border-color: var(--bor-pri-bas);
        background: var(--bac-tie1);
        box-shadow: 0 0 0 pxToRem(3) var(--bor-pri-bas);
      }

      &.Mui-disabled {
        background: $color-neutral-300;
        border-color: $color-neutral-300;
        color: var(--tex-def-tie3);
        box-shadow: none;
      }
    }

    .MuiInputBase-multiline,
    .MuiInputBase-input:not(.MuiInputBase-inputMultiline) {
      padding: pxToRem(33) pxToRem(20) pxToRem(33);
      font-size: inherit;
      line-height: inherit;
    }

    .MuiInputBase-inputMultiline {
      padding: 0;
      min-height: 3em;
      resize: vertical;
    }

    .MuiFilledInput-underline,
    .MuiInput-underline {
      &:before,
      &:after {
        content: none;
      }
    }

    .MuiFormHelperText-root {
      margin: pxToRem(4) 0 0;
      color: var(--tex-def-tie3);

      &.Mui-error {
        color: var(--tex-err-tie1);
      }

      a {
        font-weight: 800;
        color: var(--tex-def-tie1);
        text-decoration: underline;
      }
    }

    // Specific styles for select
    .MuiNativeSelect-root.MuiNativeSelect-filled {
      padding-right: pxToRem(44);

      &:focus {
        background: transparent;
      }
    }

    .MuiNativeSelect-iconFilled {
      right: pxToRem(20);
      width: pxToRem(20);
      height: pxToRem(20);
      top: calc(50% - #{pxToRem(10)});
      color: $color-uiPrimary-600;

      &.Mui-disabled {
        color: var(--tex-def-tie3);
      }
    }

    // Hide spin control on number field
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .noLabel {
    :global {
      .MuiInputBase-multiline,
      .MuiInputBase-input:not(.MuiInputBase-inputMultiline) {
        padding: pxToRem(26) pxToRem(20) pxToRem(22);
      }
    }
  }

  // 'medium' size
  &.medium {
    .inputActionWrapper {
      top: pxToRem(10);
    }

    :global {
      .MuiInputLabel-filled {
        transform: translate(pxToRem(22), pxToRem(18)) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(pxToRem(22), pxToRem(8)) scale(0.82);
        }
      }

      .MuiInputBase-multiline,
      .MuiInputBase-input:not(.MuiInputBase-inputMultiline) {
        padding-top: pxToRem(26);
        padding-bottom: pxToRem(9);
      }
    }

    .noLabel {
      :global {
        .MuiInputBase-multiline,
        .MuiInputBase-input:not(.MuiInputBase-inputMultiline) {
          padding-top: pxToRem(20);
          padding-bottom: pxToRem(15);
        }
      }
    }
  }

  // 'small' size
  &.small {
    .inputActionWrapper {
      top: pxToRem(8);
    }

    :global {
      .MuiInputBase-root {
        border-width: pxToRem(1);
      }

      .MuiInputLabel-filled {
        transform: translate(pxToRem(22), pxToRem(17)) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(pxToRem(22), pxToRem(7)) scale(0.82);
        }
      }

      .MuiInputBase-multiline,
      .MuiInputBase-input:not(.MuiInputBase-inputMultiline) {
        padding-top: pxToRem(25);
        padding-bottom: pxToRem(7);
      }
    }

    .noLabel {
      :global {
        .MuiInputBase-multiline,
        .MuiInputBase-input:not(.MuiInputBase-inputMultiline) {
          padding-top: pxToRem(18);
          padding-bottom: pxToRem(14);
        }
      }
    }
  }
}

.inputActionWrapper {
  position: absolute;
  right: pxToRem(12);
  top: pxToRem(16);
}

.labelOutside {
  :global {
    label.MuiFormLabel-root.MuiInputLabel-filled {
      transform: unset;
      position: relative;
      color: var(--tex-def-tie2);
      padding: 0 0 pxToRem(8) pxToRem(2);

      &.MuiInputLabel-shrink {
        transform: unset;
      }
    }

    // Material is using a highly specific selector along with '!important' to hide the placeholder,
    // so unfortunately we need to follow suit
    // label[data-shrink='false'] + .MuiInputBase-formControl .MuiInputBase-input::placeholder {
    label,
    label[data-shrink='false'] {
      & + .MuiInputBase-formControl .MuiInputBase-input::placeholder {
        opacity: 1 !important;
        color: var(--tex-def-tie3);
      }
    }

    .MuiInputBase-root.MuiInput-root {
      input.MuiInputBase-input.MuiInput-input,
      select.MuiNativeSelect-root.MuiNativeSelect-select {
        padding-top: pxToRem(18);
        padding-bottom: pxToRem(17);
      }
    }
  }
}

// Special styles for password input
.passwordHelpText {
  display: grid;
  row-gap: pxToRem(4);
  padding: 0 0 0 pxToRem(10);
  list-style: none;
}
.passwordHelpTextItem {
  @include fontSize(14, 17);
  margin: 0;
  font-weight: 400;
  display: grid;
  column-gap: pxToRem(8);
  grid-template-columns: pxToRem(16) 1fr;

  &.passwordHelpTextValid {
    font-weight: 800;
    color: $color-sentimental-success-800;
  }

  &.passwordHelpTextInvalid {
    color: var(--tex-err-tie1);
  }

  :global {
    .icon {
      font-size: pxToRem(16);
    }
  }
}

// Hide reveal icon specifically for Microsoft Edge
.passwordWrapper {
  :global(input::-ms-reveal) {
    display: none;
  }
}

// Special style for select
.select {
  .placeholder {
    @include fontSize(17, 24);
    color: var(--tex-def-tie3);
  }

  .loading {
    width: 100%;
    max-width: 100%;
    border-radius: var(--cor_rad-xs);
  }
}

// Special style for checkboxes and radios
.checkbox,
.radio,
.switch {
  .divider {
    width: 100%;
    color: $color-neutral-300;
  }

  :global {
    .MuiFormLabel-root {
      @include fontSize(15, 20);
      font-weight: 400;
      color: var(--tex-def-tie2);
      padding-top: pxToRem(16);
      margin-bottom: pxToRem(8);
    }

    .MuiFormControlLabel-root {
      align-items: flex-start;
      margin-right: pxToRem(-11);
      padding-right: pxToRem(11);

      &.Mui-disabled {
        color: var(--tex-def-tie3);

        .MuiIconButton-label,
        .MuiFormControlLabel-label {
          color: inherit;
        }
      }
    }

    .MuiIconButton-label {
      color: $color-uiPrimary-600;
    }

    .MuiFormControlLabel-labelPlacementStart {
      justify-content: space-between;
      margin-left: pxToRem(-11);
      padding-left: pxToRem(11);
      padding-right: 0;
    }

    .MuiFormControlLabel-label {
      padding-top: pxToRem(10);
      padding-bottom: pxToRem(4);
      @include fontSize(17, 24);
      font-weight: 800;
      color: var(--tex-def-tie1);

      a {
        color: inherit;
      }

      // Multiple lines in the option
      &:has(.multilines) {
        h4 {
          margin: 0;
        }
        p {
          @include fontSize(14, 18);
          color: var(--tex-def-tie2);
          font-weight: 400;
          margin: 0;
        }
      }
    }

    // Focus/hover styles
    .MuiIconButton-colorPrimary {
      &:hover,
      &.Mui-checked:hover,
      &.Mui-focusVisible {
        background: transparent;
      }
    }
  }
}

.checkbox {
  &.error {
    :global {
      .MuiIconButton-label {
        color: var(--tex-err-tie1);
      }
    }
  }
}

.checkbox,
.radio {
  :global {
    .MuiFormGroup-root {
      row-gap: pxToRem(4);
    }

    .MuiFormControlLabel-root {
      border-radius: pxToRem(6);

      &:hover {
        background: $color-uiPrimary-025;
      }
      &:has(input:checked) {
        background: $color-uiPrimary-050;
      }
    }
  }

  &.card {
    &.error {
      :global {
        .MuiFormControlLabel-root {
          box-shadow:
            inset 0 0 0 pxToRem(2) $color-sentimental-error-600,
            var(--elevation-2dp);
        }
      }
    }

    :global {
      legend.MuiFormLabel-root {
        @include fontSize(17, 24);
        font-weight: 800;
        color: var(--tex-def-tie1);
      }

      .MuiFormGroup-root {
        row-gap: pxToRem(8);
      }

      .MuiFormControlLabel-root:has(.multilines) {
        padding-top: pxToRem(12);

        .MuiFormControlLabel-label {
          padding-top: 0;
        }
      }

      .MuiFormControlLabel-label {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .MuiButtonBase-root {
        padding-right: pxToRem(12);
      }

      .MuiFormControlLabel-root {
        position: relative;
        overflow: hidden;
        margin: 0;
        padding: pxToRem(8);
        border-radius: var(--cor_rad-md);
        background: var(--bac-tie1);
        box-shadow: var(--elevation-2dp);
        border: pxToRem(1) solid var(--whi-100);
        outline: none;

        &:hover {
          box-shadow: 0 0 0 pxToRem(1) var(--bor-neu-bas);
        }

        &:focus-visible,
        &:has(:focus-visible) {
          background: var(--sta-on_bac-hov);
          border-color: var(--bor-neu-emp);
          box-shadow: 0 0 0 pxToRem(1) var(--bor-neu-emp);
        }

        &:has(.Mui-focusVisible) {
          background: var(--sta-on_bac-hov);
          border-color: var(--bor-neu-emp);
          box-shadow: 0 0 0 pxToRem(1) var(--bor-neu-emp);
        }

        &:has(input:checked) {
          background: var(--for-pri-lig);
          border-color: var(--bor-pri-bas);
          outline: none;

          &:hover {
            background: var(--bac-tie1);
            border-color: var(--bor-pri-bas);
            box-shadow: 0 0 0 pxToRem(1) var(--bor-pri-bas);
          }

          &:not(:hover):focus-visible,
          &:not(:hover):has(:focus-visible) {
            @include maskedBackground(var(--sta-sec-hov));
            border-color: var(--bor-pri-bas);
            box-shadow: 0 0 0 pxToRem(1) var(--bor-pri-bas);
          }

          &:has(.Mui-focusVisible) {
            background: $color-uiPrimary-050;
          }
        }
      }
    }
  }
}

// Special style for switch
.switch {
  :global {
    .MuiFormGroup-root {
      background: var(--for-neu-tie2);
      border-radius: var(--cor_rad-xs);
      padding: pxToRem(12) pxToRem(16);

      &:has(.Mui-focusVisible) {
        box-shadow: 0 0 0 pxToRem(4) $color-uiPrimary-900;
      }
    }

    .MuiFormControlLabel-root {
      margin-left: 0;
      margin-right: 0;
      column-gap: pxToRem(10);
    }

    .MuiFormControlLabel-label {
      padding-top: 0;
      font-weight: 400;
      @include fontSize(13, 18);
      align-self: center;
    }

    .MuiSwitch-root {
      width: pxToRem(48);
      height: pxToRem(24);
      padding: 0 pxToRem(2);
    }

    .MuiIconButton-root:hover {
      background-color: transparent;
    }

    .MuiSwitch-switchBase {
      padding: pxToRem(2) pxToRem(4);
    }

    .MuiSwitch-track {
      width: 100%;
      height: 100%;
      border-radius: var(--cor_rad-md);
      background: $color-neutral-400;
      opacity: 1;
    }

    .Mui-checked:not(.Mui-disabled) + .MuiSwitch-track {
      background: $color-uiPrimary-600;
      opacity: 1;
    }

    .MuiSwitch-thumb {
      width: pxToRem(20);
      height: pxToRem(20);
      background: var(--bac-tie1);
      box-shadow: var(--elevation-0dp);

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        width: pxToRem(1);
        height: pxToRem(13);
        background: $color-uiPrimary-600;
      }
      &:before {
        transform: translate(pxToRem(9), pxToRem(3.5)) rotate(45deg);
      }
      &:after {
        transform: translate(pxToRem(9), pxToRem(3.5)) rotate(-45deg);
      }
    }

    .Mui-checked .MuiSwitch-thumb {
      &:before {
        transform: translate(pxToRem(12), pxToRem(4)) rotate(45deg) scaleY(0.7);
      }
      &:after {
        transform: translate(pxToRem(7), pxToRem(5)) rotate(-45deg) scaleY(0.45);
      }
    }
  }

  &.error {
    :global {
      .MuiFormGroup-root {
        box-shadow: 0 0 0 pxToRem(2) $color-sentimental-error-600;
      }
    }
  }
}

// Special style for date picker
.datePicker {
  :global {
    .MuiFormControl-root {
      display: block;
    }

    .MuiInputLabel-root {
      z-index: 1;
      pointer-events: none;
    }
    .MuiButtonBase-root {
      padding: pxToRem(4);
      margin-right: pxToRem(4);
      &.Mui-focusVisible {
        background-color: rgba(0, 0, 0, 0.14);
      }
    }
  }

  // 'medium' size
  &.medium {
    :global {
      .MuiInputLabel-root {
        transform: translate(pxToRem(22), pxToRem(18)) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(pxToRem(22), pxToRem(8)) scale(0.82);
        }
      }
    }
  }

  // 'small' size
  &.small {
    :global {
      .MuiInputLabel-root {
        transform: translate(pxToRem(22), pxToRem(17)) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(pxToRem(22), pxToRem(7)) scale(0.82);
        }
      }
    }
  }
}

.datePickerLeftArrowButton,
.datePickerRightArrowButton {
  padding: pxToRem(2);
  outline: $border-2;
  border-radius: var(--cor_rad-xs);
  color: $color-uiPrimary-000;
}

.datePickerLeftArrowButton {
  margin-left: pxToRem(8);
}

.datePickerRightArrowButton {
  margin-right: pxToRem(8);
}

.helperText {
  position: absolute;
  left: pxToRem(20);
  bottom: pxToRem(16);
  margin: 0;
  @include fontSize(14, 18);
  letter-spacing: pxToRem(0.2);
  color: var(--tex-def-tie3);
}

// Special styles for location
.location {
  .locationIcon {
    fill: $color-uiPrimary-600;
  }

  :global {
    .MuiInputBase-root {
      .MuiInputBase-input.MuiInput-input {
        padding: pxToRem(28) pxToRem(16) pxToRem(6) pxToRem(20);
      }

      .MuiAutocomplete-endAdornment {
        right: pxToRem(16);
      }
    }
  }
}

ul.listbox {
  max-height: pxToRem(192);

  .locationOption {
    display: flex;
    align-items: center;
    gap: pxToRem(12);
    @include fontSize(17, 24);
    font-family: inherit;
    padding: pxToRem(12) pxToRem(16);
  }
}

@include sm {
  .switch {
    :global {
      .MuiFormControlLabel-label {
        @include fontSize(15, 20);
      }
    }
  }
}

.chipContainer {
  padding-top: pxToRem(16);
  display: inline-flex;
  flex-wrap: wrap;
  gap: pxToRem(6);
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  @include fontSize(17, 24);
  color: var(--tex-def-tie1);

  p {
    margin: 0;
  }
}

.greeting {
  margin: 0 0 pxToRem(8);
  @include fontSize(17, 24);
  font-weight: 400;
}

.email {
  @include fontSize(15, 20);
  word-break: break-word;
}

.buttonContainer {
  margin-top: pxToRem(32);
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
}

a.button,
button.button {
  justify-content: left;
  padding: pxToRem(12) pxToRem(16);
  span {
    @include fontSize(17, 24);
    font-weight: 400;
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  scroll-margin-top: pxToRem(134);
}

.modalWrapper {
  .content {
    padding: 0;
  }
}

.formContent {
  padding: pxToRem(16) 0;
  :global {
    .MuiFormControl-root {
      margin: 0;
    }

    .MuiInputLabel-root {
      transform: translate(pxToRem(16), pxToRem(25)) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(pxToRem(16), pxToRem(15)) scale(0.82);
      }
    }
  }
}

.header {
  margin: 0 0 pxToRem(24);
  font-weight: 800;
  @include fontSize(26, 32);
  color: var(--tex-def-tie1);
}

.sectionTitle {
  @include fontSize(17, 24);
  margin: pxToRem(24) 0 pxToRem(12) 0;
}

.messageInput {
  :global {
    .MuiInputBase-multiline,
    .MuiInputBase-input:not(.MuiInputBase-inputMultiline) {
      padding-left: pxToRem(16);
    }
  }

  p {
    left: pxToRem(16);
  }
}

.checkboxContainer {
  margin-bottom: pxToRem(16);

  :global {
    .MuiFormControlLabel-root:has(input:checked),
    .MuiFormControlLabel-root:has(input:focus) {
      background: var(--bac-tie1);
    }
  }
}

.inputContainer {
  display: grid;
  grid-gap: pxToRem(16);
}

.recaptchaContainer {
  margin: pxToRem(16) 0 pxToRem(16);
}

.submitButton {
  width: 100%;
}

.successMessageContainer {
  margin-top: pxToRem(50);
  text-align: center;
  color: var(--tex-def-tie1);

  h6 {
    margin: pxToRem(14) 0 pxToRem(4);
    @include fontSize(20, 28);
    font-weight: 800;
  }

  p {
    margin: 0 0 pxToRem(24);
    @include fontSize(17, 24);
  }

  :global {
    .MuiButton-root {
      padding: pxToRem(11) pxToRem(12);
    }

    .MuiButton-label {
      @include fontSize(15, 18);
      font-weight: 600;
    }
  }
}

svg.iconCircleCheck {
  width: pxToRem(80);
  height: pxToRem(80);
}

.footer {
  width: 100%;
}

.privacyCollectionNotice {
  margin-top: pxToRem(16);
  @include fontSize(14, 20);
  color: var(--tex-def-tie1);

  a {
    font-weight: 800;
  }
}

@include sm {
  .inputContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@include md {
  .modalWrapper {
    width: pxToRem(640);
  }
}

@include lg {
  .header {
    margin: 0 0 pxToRem(32);
    @include fontSize(32, 40);
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.magicErrorHeader {
  display: flex;
  justify-content: center;
  margin-top: pxToRem(40);
}

.emptyAccountIcon {
  width: pxToRem(100);
  height: pxToRem(100);

  .icon {
    width: 100%;
    height: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - pxToRem(104));
}

.errorText {
  @include fontSize(26, 32);
  font-weight: 800;
  margin: pxToRem(8) 0 pxToRem(48);
}

.buttonContainer {
  padding: pxToRem(16);
  width: pxToRem(205);
  height: pxToRem(56);
}

@include sm {
  .magicErrorHeader {
    margin-top: pxToRem(48);
  }
}

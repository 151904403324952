@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  @include rootSection(var(--bac-tie2));
  padding-top: calc(#{pxToRem(24)} + $headerHeight);
  padding-bottom: pxToRem(104);
  max-width: pxToRem(1280);
  min-height: 70vh;
}

.sidebar {
  display: none;
}

@include sm {
  .root {
    padding-top: calc(#{pxToRem(32)} + $headerHeight);
  }
}

@include md {
  .root {
    display: grid;
    grid-template: '🍍 🥥' 1fr/14rem 1fr;
    column-gap: pxToRem(52);
  }

  .sidebar {
    display: block;
    grid-area: 🍍;
  }

  .content {
    grid-area: 🥥;
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

@mixin buttonSize($size) {
  --minHeight: var(--minHeight-#{$size});
  --padding: var(--padding-#{$size});
  --borderRadius: var(--borderRadius-#{$size});
  --fontSize: var(--fontSize-#{$size});
  --loadingSize: var(--loadingSize-#{$size});
  --paddingContent: var(--paddingContent-#{$size});
  --marginIcon: var(--marginIcon-#{$size});
  --sizeIconButton: var(--sizeIconButton-#{$size});
  --sizeIconButtonIcon: var(--sizeIconButtonIcon-#{$size});
}

.root {
  --minHeight-xs: #{pxToRem(32)};
  --padding-xs: #{pxToRem(11)} #{pxToRem(8)};
  --borderRadius-xs: #{pxToRem(10)};
  --fontSize-xs: #{pxToRem(15)};
  --loadingSize-xs: #{pxToRem(24)};
  --paddingContent-xs: #{pxToRem(4)};
  --marginIcon-xs: #{pxToRem(6)};
  --sizeIconButton-xs: #{pxToRem(32)};
  --sizeIconButtonIcon-xs: #{pxToRem(20)};

  --minHeight-sm: #{pxToRem(40)};
  --padding-sm: #{pxToRem(15)} #{pxToRem(8)};
  --borderRadius-sm: #{pxToRem(12)};
  --fontSize-sm: #{pxToRem(15)};
  --loadingSize-sm: #{pxToRem(24)};
  --paddingContent-sm: #{pxToRem(6)};
  --marginIcon-sm: #{pxToRem(6)};
  --sizeIconButton-sm: #{pxToRem(40)};
  --sizeIconButtonIcon-sm: #{pxToRem(24)};

  --minHeight-md: #{pxToRem(56)};
  --padding-md: #{pxToRem(22)} #{pxToRem(14)};
  --borderRadius-md: #{pxToRem(16)};
  --fontSize-md: #{pxToRem(17)};
  --loadingSize-md: #{pxToRem(24)};
  --paddingContent-md: #{pxToRem(6)};
  --marginIcon-md: #{pxToRem(8)};
  --sizeIconButton-md: #{pxToRem(56)};
  --sizeIconButtonIcon-md: #{pxToRem(24)};

  --minHeight-lg: #{pxToRem(72)};
  --padding-lg: #{pxToRem(28)} #{pxToRem(16)};
  --borderRadius-lg: #{pxToRem(16)};
  --fontSize-lg: #{pxToRem(23)};
  --loadingSize-lg: #{pxToRem(32)};
  --paddingContent-lg: #{pxToRem(6)};
  --marginIcon-lg: #{pxToRem(10)};
  --sizeIconButton-lg: #{pxToRem(72)};
  --sizeIconButtonIcon-lg: #{pxToRem(28)};

  font-family: inherit;
  appearance: none;
  border: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: var(--borderRadius);
  text-decoration: none;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
  transition:
    background linear 150ms,
    color linear 150ms;
  min-height: var(--minHeight);
  padding: var(--padding);
  font-size: var(--fontSize);
  line-height: 70%;
  font-weight: 600;

  &.disabled {
    cursor: default;

    :global {
      .shade {
        display: none;
      }
    }
  }

  &.disabled.isLoading {
    cursor: wait;
  }

  .progress {
    display: block;
    font-size: var(--loadingSize);
  }

  :global {
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      position: relative;

      .icon {
        display: block;
        font-size: pxToRem(24);
        margin-top: pxToRem(-8);
        margin-bottom: pxToRem(-8);
      }

      > .icon {
        &:first-child {
          margin-right: pxToRem(4);
        }
        &:last-child {
          margin-left: pxToRem(4);
        }
      }
    }
  }

  :global(.contentLabel) {
    padding-left: var(--paddingContent);
    padding-right: var(--paddingContent);
  }

  // Sizes
  &:global(.button--large) {
    @include buttonSize('lg');
  }
  &:global(.button--medium) {
    @include buttonSize('md');
  }
  &:global(.button--small) {
    @include buttonSize('sm');
  }
  &:global(.button--xsmall) {
    @include buttonSize('xs');
  }
  // 'accent' variant
  &:global(.button--accent) {
    --shade-gradient-col1: #ff385c;
    --shade-gradient-col2: #d5185c;

    background: var(--shade-gradient-col1);
    color: var(--tex-inv-tie1);

    &.disabled {
      background: var(--for-pri-dis);
      color: var(--tex-inv-tie3);
    }
  }

  // 'primary' variant
  &:global(.button--primary) {
    --shade-gradient-col1: #541ec8;
    --shade-gradient-col2: #311273;

    background: var(--shade-gradient-col1);
    color: var(--tex-inv-tie1);

    :global(.shade:before) {
      --shade-gradient-col1: #42189b;
      --shade-gradient-col2: #311273;
    }

    &.disabled {
      background: var(--for-pri-dis);
      color: var(--tex-inv-tie3);
    }
  }

  // 'secondary' variant
  &:global(.button--secondary) {
    --shade-gradient-col1: #6b34e029;
    --shade-gradient-col2: #ffffff00;

    color: var(--for-pri-bas);
    background: var(--bac-tie1);
    border: thin solid var(--bor-pri-bas);

    :global(.shade:before) {
      display: none;
    }

    &.disabled {
      color: var(--tex-def-tie3);
      background: var(--bac-tie1);
      border-color: var(--bor-pri-dis);
    }
  }

  // 'tertiary' variant
  &:global(.button--tertiary) {
    color: var(--tex-def-tie1);
    background: transparent;

    :global(.shade::before) {
      display: none;
    }

    &.disabled {
      color: var(--tex-def-tie3);
    }
  }

  // 'link' variant
  &:global(.button--link) {
    background: transparent;
    color: var(--tex-def-tie1);
    text-decoration: underline;

    &:global(.button--small),
    &:global(.button--medium),
    &:global(.button--large) {
      padding: pxToRem(8);
      margin: pxToRem(-8);
    }

    :global(.shade) {
      display: none;
    }

    &:not(.disabled) {
      &:hover,
      &:focus-visible {
        text-decoration: none;
        background: transparent;
        color: var(--tex-def-tie1);
      }
    }

    &.disabled {
      color: var(--tex-def-tie3);
      text-decoration: underline;
    }
  }

  &.buttonIcon {
    align-items: center;
    justify-content: center;
    width: var(--sizeIconButton);
    height: var(--sizeIconButton);
    min-width: var(--sizeIconButton);
    min-height: var(--sizeIconButton);
    border-radius: var(--cor_rad-xs);

    &:global(.button--round) {
      border-radius: 50%;
    }

    :global(.content .icon) {
      font-size: var(--sizeIconButtonIcon);
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.isLoading {
  :global(.content) {
    opacity: 0;
  }
}

.fullWidth {
  width: 100%;
}

.loadingContainer {
  font-size: pxToRem(24);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-0.5em, -0.5em);
}

/** Safari only styles (Safari doesn't support ascent-override which mess-up the alignment between text and icon) */
@supports (-webkit-hyphens: none) {
  .root {
    :global(.content) {
      margin-top: -0.075em;
      margin-bottom: -0.075em;
    }

    :global(.contentLabel) {
      padding-top: 0.15em;
    }
  }
}

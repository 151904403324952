@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $zIndex-preview;
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    border: pxToRem(8) solid $color-sentimental-caution-200;
    box-sizing: border-box;
    opacity: 0.5;
  }
}

.indicator {
  position: relative;
  pointer-events: auto;
  margin: auto;
  width: calc(100% - #{pxToRem(40)});
  max-width: pxToRem(340);
  border-radius: pxToRem(8) pxToRem(8) 0 0;
  background: $color-sentimental-caution-200;
  color: $color-sentimental-caution-800;
  box-shadow: var(--elevation-2dp);
  padding: pxToRem(8);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

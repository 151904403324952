@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
  width: 100%;

  :global {
    .shade_container {
      background-color: transparent;
      --shade-gradient-col1: unset;
      --shade-gradient-col2: unset;
    }
  }
}

.closeButton {
  padding: pxToRem(6);
}

.closeIcon {
  width: pxToRem(20);
  height: pxToRem(20);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: pxToRem(8);
  padding: pxToRem(16);
  border-radius: pxToRem(8);
  background-color: $color-neutral-000;
}

.imageWrapper {
  position: relative;
  height: auto;
  width: 100%;
  aspect-ratio: 16 / 9;

  .image {
    border-radius: pxToRem(8);
    object-fit: contain;
    width: 100%;
    height: auto;
  }

  svg {
    width: pxToRem(24);
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
}

.headerActionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerMeta {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: pxToRem(4);
}

.meta {
  @include fontSize(14, 18);
  color: var(--color-opacity-dark-lowEmphasis);
}

.title {
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--color-opacity-dark-highEmphasis);
}

.actionButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: pxToRem(8);
}

.description {
  @include fontSize(15, 20);
  color: var(--color-opacity-dark-highEmphasis);
}

@include sm {
  .root {
    flex-direction: column;
    align-items: flex-end;
  }
}

@include md {
  .root {
    flex-direction: row;
    align-items: flex-start;
  }
}

@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

$inputBottomMargin: pxToRem(24);

.root {
  display: flex;
  flex-direction: column;
  gap: pxToRem(4);
  margin-bottom: $inputBottomMargin;
  position: relative;

  :global(.wrapper) {
    margin-bottom: 0;
  }
  .emailInput {
    :global(input) {
      padding-right: pxToRem(56);
    }
  }
}

.helperText {
  @include fontSize(14, 20);
}

.inputIndicator {
  position: absolute;
  right: pxToRem(8);
  top: 50%;
  transform: translateY(-50%);
}

.suggestedEmail {
  &:global(.button--link.button--medium) {
    min-height: auto;
    background-color: transparent;
    outline: none;
    border: 0;
    padding: 0;
    margin: 0;
    @include fontSize(14, 20);

    :global(.contentLabel) {
      padding: 0;
    }
  }
}

.hiddenInput {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.headerContainer {
  display: grid;
  grid-template:
    '🚗 🏎 🚌' auto
    '🚑 🚑 🚑' auto / 1fr pxToRem(150) 1fr;
  padding: pxToRem(20) pxToRem(16);
  margin: auto;
  background-color: var(--bac-tie1);
  max-width: $max-width-section;
}

.logo {
  grid-area: 🏎;
  justify-self: center;
  width: pxToRem(74);
  height: pxToRem(24);
}

.backContainer {
  grid-area: 🚗;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .backMobile {
    margin: pxToRem(-8) 0 0 pxToRem(-10);

    svg {
      width: pxToRem(24);
      height: pxToRem(24);
    }
  }

  .backDesktop {
    display: none;
  }
}

@include sm {
  .headerContainer {
    background-color: transparent;
    padding-bottom: pxToRem(90);
  }

  .logo {
    width: pxToRem(110);
    height: pxToRem(36);
  }

  .backContainer {
    align-items: flex-start;
    justify-content: flex-start;

    .backMobile {
      display: none;
    }

    .backDesktop {
      display: inline-flex;
      background: transparent;
      @include fontSize(15, 18);

      svg {
        width: pxToRem(24);
        height: pxToRem(24);
      }
    }
  }
}

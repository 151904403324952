@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;

.root {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  height: 100%;
  position: relative;
}

.blurBgWrapper {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blurBg {
  scale: 1.2;
  filter: blur(100px);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.damageWrapper {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.imperfectionContentWrapper {
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
  }
}

.imperfectionCard {
  height: 100%;
  padding: 0 pxToRem(8) pxToRem(8) pxToRem(8);
  border-bottom-left-radius: pxToRem(16);
  border-bottom-right-radius: pxToRem(16);

  :global(.content) {
    height: 100%;
    border-bottom-left-radius: pxToRem(16);
    border-bottom-right-radius: pxToRem(16);
  }
}

@include md {
  .root {
    grid-template-columns: auto pxToRem(400);
    grid-template-rows: repeat(6, 1fr);
  }

  .damageWrapper {
    grid-row: 2 / span 4;
  }
  .imperfectionContentWrapper {
    grid-row: 3/ 5;
    min-height: pxToRem(300);
  }
  .imperfectionCard {
    padding: 0 pxToRem(24) pxToRem(0) pxToRem(16);
    :global(.content) {
      border-radius: pxToRem(12);
    }
  }
}

@include sml {
  .root {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row;
  }
  .damageWrapper {
    grid-column: 2 / 3;
    grid-row: 1/2;
  }
  .imperfectionContentWrapper {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .imperfectionCard {
    padding: pxToRem(8);
    :global(.content) {
      border-radius: pxToRem(12);
    }
  }
}

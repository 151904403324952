@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;

.form {
  padding: 0 pxToRem(16) pxToRem(32);
  max-width: pxToRem(444);
  margin: auto;
}

.verificationTokenFormContainer {
  text-align: center;
  padding: pxToRem(16);

  :global {
    input {
      width: pxToRem(56);
      border: pxToRem(1) solid #ebf2f5 !important;
      height: pxToRem(56);
      font-size: pxToRem(32);
      box-shadow:
        0 pxToRem(2) pxToRem(1) pxToRem(-1) rgb(62 60 94 / 10%),
        0 pxToRem(3) pxToRem(3) pxToRem(-2) rgb(150 147 189 / 40%);
      text-align: center;
      margin-left: pxToRem(8);
      margin-right: pxToRem(8);
      border-radius: var(--cor_rad-xs);

      &:focus {
        outline: none;
        box-shadow:
          0 pxToRem(2) pxToRem(1) pxToRem(-1) rgb(62 60 94 / 10%),
          0 pxToRem(3) pxToRem(3) pxToRem(-2) rgb(150 147 189 / 40%),
          0 0 0 pxToRem(4) $color-uiPrimary-600;
      }
    }
  }

  &.verificationTokenFormContainerError {
    :global {
      input {
        box-shadow:
          0 pxToRem(2) pxToRem(1) pxToRem(-1) rgb(62 60 94 / 10%),
          0 pxToRem(3) pxToRem(3) pxToRem(-2) rgb(150 147 189 / 40%),
          0 0 0 pxToRem(2) $color-sentimental-error-600;

        &:focus {
          box-shadow:
            0 pxToRem(2) pxToRem(1) pxToRem(-1) rgb(62 60 94 / 10%),
            0 pxToRem(3) pxToRem(3) pxToRem(-2) rgb(150 147 189 / 40%),
            0 0 0 pxToRem(4) $color-uiPrimary-600;
        }
      }
    }
  }
}

.verificationCodeError {
  display: grid;
  text-align: center;
  margin-bottom: pxToRem(32);
  color: var(--tex-err-tie1);
}

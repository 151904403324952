@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.form,
.signupSuccess {
  padding: 0 pxToRem(16) pxToRem(32);
  max-width: pxToRem(444);
  margin: auto;

  .signupPersonalDetailsForm {
    :global {
      .MuiFormControl-root {
        margin: pxToRem(16) 0;
      }
    }
  }
}

.checkboxContainer {
  margin: pxToRem(16) 0;
}

.confirmDetailsForm {
  display: grid;
  gap: pxToRem(16);
}

.formFooter {
  margin-top: pxToRem(16);
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
}

.privacyAndTerms {
  max-width: pxToRem(444);
  margin: pxToRem(-16) auto auto auto;
  padding: 0 pxToRem(16) pxToRem(32);
  a {
    @include fontSize(14, 18);
    color: var(--tex-def-tie1);
    text-decoration: underline;
    font-weight: 800;
  }
}

.formForgotPassword {
  margin-left: pxToRem(-4);
}

.formText {
  margin-bottom: pxToRem(32);
  @include fontSize(17, 24);
  color: var(--tex-def-tie1);

  p {
    margin: 0 0 pxToRem(8);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.terms {
  @include fontSize(14, 18);
  font-weight: 400;
  color: var(--tex-def-tie1);

  a {
    color: rgba(51, 46, 105, 1);
    font-weight: 800;
  }
}

// Styles for the verification code
.verificationTokenFormContainer {
  text-align: center;
  padding: pxToRem(16);

  :global {
    input {
      width: pxToRem(56);
      border: pxToRem(1) solid #ebf2f5 !important;
      height: pxToRem(56);
      font-size: pxToRem(32);
      box-shadow:
        0 pxToRem(2) pxToRem(1) pxToRem(-1) rgb(62 60 94 / 10%),
        0 pxToRem(3) pxToRem(3) pxToRem(-2) rgb(150 147 189 / 40%);
      text-align: center;
      margin-left: pxToRem(8);
      margin-right: pxToRem(8);
      border-radius: var(--cor_rad-xs);

      &:focus {
        outline: none;
        box-shadow:
          0 pxToRem(2) pxToRem(1) pxToRem(-1) rgb(62 60 94 / 10%),
          0 pxToRem(3) pxToRem(3) pxToRem(-2) rgb(150 147 189 / 40%),
          0 0 0 pxToRem(4) $color-uiPrimary-600;
      }
    }
  }

  &.verificationTokenFormContainerError {
    :global {
      input {
        box-shadow:
          0 pxToRem(2) pxToRem(1) pxToRem(-1) rgb(62 60 94 / 10%),
          0 pxToRem(3) pxToRem(3) pxToRem(-2) rgb(150 147 189 / 40%),
          0 0 0 pxToRem(2) $color-sentimental-error-600;

        &:focus {
          box-shadow:
            0 pxToRem(2) pxToRem(1) pxToRem(-1) rgb(62 60 94 / 10%),
            0 pxToRem(3) pxToRem(3) pxToRem(-2) rgb(150 147 189 / 40%),
            0 0 0 pxToRem(4) $color-uiPrimary-600;
        }
      }
    }
  }

  .verificationCodeError {
    text-align: center;
    margin-top: pxToRem(8);
  }
}

.resendCode {
  text-align: center;
  margin-bottom: pxToRem(48);

  .resendCodeButton {
    margin-top: pxToRem(-8);
    margin-bottom: pxToRem(-8);
    padding-top: pxToRem(8);
    padding-bottom: pxToRem(8);
  }
}

.resendCodeText {
  margin: 0;
  color: var(--tex-def-tie2);
  @include fontSize(15, 20);
}

//
// Login Success
//

.signupSuccess {
  display: flex;
  flex-direction: column;

  .imageContainer {
    margin: auto;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: pxToRem(24);
    margin: pxToRem(24) 0 0;
    text-align: center;

    h5 {
      margin: 0;
      @include fontSize(26, 32);
      font-weight: 800;
      color: var(--tex-def-tie1);
    }

    p {
      margin: 0;
      @include fontSize(17, 24);
      font-weight: 400;
      color: var(--tex-def-tie1);
    }

    p:last-child {
      margin: 0;
      @include fontSize(15, 20);
      font-weight: 400;
      color: rgba(21, 13, 44, 0.7);
    }
  }

  .signupSuccessButton {
    @include fontSize(15, 20);
    font-weight: 800;
    color: rgba(21, 13, 44, 0.7);
    text-decoration: underline;
    appearance: none;
    font-family: inherit;
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

@include sm {
  .formText {
    text-align: center;
  }

  .signupSuccess {
    padding-top: 0;
    margin-top: pxToRem(-12);
  }
}

.ssoButtonsContainer {
  display: grid;
  gap: pxToRem(8);

  .ssoDivider {
    color: var(--tex-def-tie1);
    font-weight: 900;
    font-size: pxToRem(12);
  }
}

.loadingContainer {
  margin: auto;
  display: grid;
  row-gap: pxToRem(16);
  max-width: pxToRem(230);
  padding-top: pxToRem(32);
}

.progressContainer {
  margin: auto;
}

.loadingText {
  @include fontSize(15, 20);
  font-weight: 800;
  color: $color-uiPrimary-600;
  text-align: center;
}

.verificationCodeError {
  display: grid;
  text-align: center;
  margin-bottom: pxToRem(32);
  color: var(--tex-err-tie1);
}

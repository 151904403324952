@use 'src/theme/helpers' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.root {
  background-color: $color-neutral-000;
  padding: pxToRem(8);
  border-radius: pxToRem(8);
  display: flex;
  align-items: center;
  justify-content: center;

  .keyFeatureSwitch {
    margin-bottom: 0;
  }
  :global(.label) {
    color: var(--tex-def-tie1);
    @include fontSize(15, 22);
    font-weight: 600;
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

.root {
  display: inline-block;
  font-size: pxToRem(24);
  width: 1em;
  height: 1em;
  transform-origin: 50% 50%;
  animation: rotate 1.4s linear infinite;
}

.root .icon {
  display: block;
  width: 100%;
  height: 100%;
}

.circle {
  stroke: currentColor;
  animation: dash 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: pxToRem(4);
  background-color: $color-uiPrimary-050;
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(16) pxToRem(14);

  .content {
    display: flex;
    justify-content: space-between;

    .edit {
      &,
      &:hover,
      &:focus {
        width: unset;
        height: unset;
        padding: 0;
        border: none;
        cursor: pointer;
        background: transparent;
        color: $color-uiPrimary-600;
      }
    }
  }
}

.textWrapper {
  display: flex;
  align-items: center;
  gap: pxToRem(8);
}

.text {
  font-family: inherit;
  font-style: normal;
  @include fontSize(17, 24);
  color: var(--tex-def-tie1);

  &.bold {
    font-weight: 700;
  }
}

.descriptionMobile {
  padding-right: pxToRem(32);
  font-family: inherit;
  @include fontSize(15, 20);
  color: var(--tex-def-tie2);
}

@include sm {
  .descriptionMobile {
    display: none;
  }
}

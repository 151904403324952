@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.container {
  font-family: inherit;
  font-style: normal;
  display: flex;
  flex-direction: column;
}

.deliveryOptionsWrapper {
  display: flex;
  gap: pxToRem(18);
  padding: pxToRem(16) pxToRem(0);
  align-items: flex-start;

  img {
    pointer-events: none;
  }
}

.warehouseImg {
  width: pxToRem(64);
  height: pxToRem(64);
  flex-shrink: 0;
}

.aussieImg {
  width: pxToRem(65);
  height: pxToRem(65);
  flex-shrink: 0;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 700;
  @include fontSize(17, 24);
}

.location {
  @include fontSize(17, 24);
}

.description {
  @include fontSize(15, 20);
  color: var(--tex-def-tie2);
  padding-top: pxToRem(8);
}

.footerWrapper {
  padding: pxToRem(16) pxToRem(0);
  justify-content: center;
  display: none;
  & span {
    @include fontSize(15, 20);
    color: var(--tex-def-tie2);
  }
}

.lineBreak {
  display: none;
  align-self: center;
  border-top: $border-0;
  height: pxToRem(0);
  width: calc(100% - 28px);
}

.leftOffsetWrapper {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
  padding-bottom: pxToRem(20);
}

.emailText {
  color: var(--tex-def-tie2);
  border-top: $border-0;
  padding: pxToRem(20) pxToRem(0) pxToRem(0) pxToRem(4);
  margin-top: pxToRem(4);
}

.button {
  background: $color-uiPrimary-600;
  color: white;
  border: none;
  border-radius: var(--cor_rad-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: pxToRem(0);
  height: pxToRem(56);
  font-style: normal;
  font-weight: 700;
  @include fontSize(17, 24);
  cursor: pointer;

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: pxToRem(8);
    padding: pxToRem(16) pxToRem(20) pxToRem(16) pxToRem(16);
    font-family: inherit;
    text-decoration: none;
    color: var(--tex-inv-tie1);
    width: 100%;
  }

  &:hover {
    color: $color-opacity-light-highEmphasis;
    background: $color-uiPrimary-700;
  }

  & .successMessage {
    padding-bottom: pxToRem(20);
  }
}

@include sm {
  .footerWrapper {
    display: flex;
  }
  .lineBreak {
    display: block;
  }
  .leftOffsetWrapper {
    padding-left: pxToRem(80);
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.deliverableContainer {
  height: pxToRem(96);
  display: flex;
  align-items: center;
  gap: pxToRem(16);

  img {
    pointer-events: none;
  }

  .textContainer {
    font-family: inherit;
    font-style: normal;
    display: flex;
    flex-direction: column;
    gap: pxToRem(8);
    color: var(--tex-def-tie1);
  }

  .title {
    font-weight: 800;
    @include fontSize(17, 24);
  }

  .descriptionWrapper {
    display: flex;
    align-items: center;
    gap: pxToRem(6);
  }

  .description {
    @include fontSize(15, 20);
    color: var(--tex-def-tie1);
  }
}

.deliveryImg {
  width: pxToRem(64);
  height: pxToRem(64);
}

.undeliverableContainer {
  display: flex;
  flex-direction: column;
  gap: pxToRem(10);
  background: $color-uiPrimary-050;
  font-family: inherit;
  font-style: normal;

  .lineBreak {
    border-top: $border-0;
    margin-top: 16px;
    margin-bottom: pxToRem(4);
  }

  .title {
    font-weight: 700;
    @include fontSize(15, 20);
    color: var(--tex-def-tie1);
  }
  .description {
    @include fontSize(15, 20);
    color: var(--tex-def-tie2);
    padding-bottom: pxToRem(5);
  }
}

@include sm {
  .deliverableContainer {
    .description {
      @include fontSize(17, 24);
    }
  }
}

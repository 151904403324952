@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;

  .fullWidth {
    width: 100%;
  }

  .button {
    padding: pxToRem(16) pxToRem(20);
    @include fontSize(17, 24);
    font-weight: 600;
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

@include sm {
  .formWrapper {
    .button {
      width: unset;
    }
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

.form {
  display: flex;
  align-items: flex-start;

  .button {
    background: $color-uiPrimary-600;
    color: var(--tex-inv-tie1);
    padding: pxToRem(0) pxToRem(20);
    margin-top: pxToRem(11);
    height: pxToRem(56);
    width: pxToRem(78);
    border-radius: var(--cor_rad-xs);
    line-height: pxToRem(56);

    &:hover {
      color: $color-opacity-light-highEmphasis;
      background: $color-uiPrimary-700;
    }
  }
}

.successWrapper {
  display: flex;
  justify-content: center;
  gap: pxToRem(10);
  padding: pxToRem(19) pxToRem(0) pxToRem(20) pxToRem(0);
}

.successMessage {
  font-family: inherit;
  font-style: normal;
  font-weight: 700;
  @include fontSize(14, 18);
  letter-spacing: pxToRem(0.2);
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: pxToRem(8);
  justify-content: center;
  width: 100%;
}

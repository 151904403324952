@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;

.root {
  height: 100svh;
}

.thumbnailSlider {
  display: none;
}

.navSlider {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: pxToRem(8);
  padding: pxToRem(8);
  padding-bottom: pxToRem(62);
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    // Hide scroll in Chrome, Safari and Opera
    display: none;
  }
}

.navButton {
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
  height: calc((100vw - pxToRem(16)) * 9 / 16);
  width: 100%;
  border-radius: pxToRem(8);
  scroll-snap-align: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: pxToRem(8);
    box-sizing: border-box;
  }
}

@include sm {
  .navSlider {
    padding-bottom: pxToRem(68);
  }
}

@include md {
  .root {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .thumbnailContainer {
    position: relative;
    padding-top: pxToRem(98);
    flex: 1;
    height: 100%;
  }
  .thumbnailSlider {
    display: block;
    flex: 1;
    background: #555; // mid-level grey to visually ease flashing of images
  }
  .thumbnail {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .arrow {
    position: absolute;
    z-index: 100;
    &:global(.button--tertiary) {
      color: #332e69;
      background: $color-neutral-000;
    }
  }
  .prevArrow {
    left: pxToRem(24);
    top: 50%;
    transform: translateY(-50%);
  }
  .nextArrow {
    right: pxToRem(24);
    top: 50%;
    transform: translateY(-50%);
  }
  .navContainer {
    background-color: $color-neutral-000;
    position: relative;
    overflow-y: hidden;
  }
  .navSlider {
    display: flex;
    flex-flow: row;
    width: fit-content;
    overflow-x: auto;
    overflow-y: hidden;
    height: pxToRem(128);
    padding: pxToRem(24);
    scroll-snap-type: x mandatory;
  }

  .navButton {
    flex: 0 0 auto;
    height: pxToRem(80);
    width: pxToRem(143);
    border-radius: pxToRem(16);
    scroll-snap-align: center;
    img {
      border-radius: pxToRem(16);
      opacity: 0.7;
    }
    &.navButtonActive > img {
      box-shadow: pxToRem(0) pxToRem(0) pxToRem(0) pxToRem(4) $color-uiPrimary-400;
      opacity: 1;
    }
  }

  .blurBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(100px);
    scale: 1.2;
    object-fit: cover;
  }
}

@include sml {
  .root {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .thumbnailSlider {
    display: none;
  }
  .navContainer {
    background-color: $color-neutral-000;
    position: relative;
    overflow-y: hidden;
  }
  .navSlider {
    height: 100%;
    flex-flow: row;
    width: fit-content;
    padding: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .navButton {
    flex: 0 0 auto;
    height: 100svh;
    width: calc(100svh * 16 / 9);
    img {
      opacity: 1;
    }
    &.navButtonActive > img {
      box-shadow: none;
    }
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.chatWidgetContainer {
  margin: pxToRem(24) auto 0 auto;
  width: calc(100% - pxToRem(48));

  .chatWidget {
    @include fontSize(15, 18);
    color: var(--tex-def-tie1);
    background: var(--bac-tie1);
    width: 100%;
    height: pxToRem(40);
    flex-direction: row;
    justify-content: space-between;

    span {
      width: 100%;
      padding: 0;
    }
  }
}

.buttonContent {
  display: flex;
  gap: pxToRem(8);
  align-items: center;
  justify-content: space-between;
}

.chatWidgetLabel {
  text-align: left;
  text-wrap: wrap;
}

.avatarContainer {
  position: relative;
  width: pxToRem(66);
  height: pxToRem(28);

  :nth-child(1) {
    right: pxToRem(22);
    z-index: 2;
  }

  :nth-child(2) {
    right: pxToRem(44);
    z-index: 1;
  }
}

.avatar {
  position: absolute;
  border-radius: pxToRem(100);
  border: pxToRem(1) solid $color-neutral-000;
  right: 0;
  z-index: 3;
}

@include sm {
  .chatWidgetContainer {
    top: pxToRem(64);
    right: pxToRem(16);
    left: auto;
    width: max-content;
    height: max-content;
    position: absolute;
    margin: auto;
  }

  .avatarContainer {
    width: pxToRem(90);
  }
}

@include md {
  .chatWidgetContainer {
    top: pxToRem(36);
    right: pxToRem(72);
  }
}

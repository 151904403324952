@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

//Box shadow styles for card variant
$card-background-box-shadow1:
  0 0 0 rgba(0, 0, 0, 0.04),
  0 pxToRem(2) pxToRem(8) rgba(0, 0, 0, 0.16),
  inset 0 0 0 pxToRem(1) var(--bor-pri-bas);
$card-background-box-shadow2:
  0 0 0 rgba(0, 0, 0, 0.04),
  0 pxToRem(2) pxToRem(8) rgba(0, 0, 0, 0.16),
  inset 0 0 0 pxToRem(2) var(--bor-pri-bas);
$card-background-box-shadow-error:
  inset 0 0 0 pxToRem(2) $color-sentimental-error-600,
  0 0 0 rgba(0, 0, 0, 0.04),
  0 pxToRem(2) pxToRem(8) rgba(0, 0, 0, 0.16);

$offset: 12;
$horizontalOffset: pxToRem(-$offset);

.root {
  display: grid;
  grid-template: '🎛️ 💬' auto / auto 1fr;
  column-gap: pxToRem(8);
  position: relative;
  margin: 0;
  padding: 0;
  border-radius: var(--cor_rad-xs);

  &.start {
    grid-template: '🎛️ 💬' auto / auto 1fr;
  }
  &.end {
    grid-template: '💬 🎛️' auto / 1fr auto;
  }

  &:not(:global(.disabled)) {
    cursor: pointer;

    &:focus-visible,
    &:has(:focus-visible),
    &:hover {
      :global(.fieldInput::before) {
        box-shadow: 0 0 0 pxToRem(2) var(--bor-pri-mut);
      }
    }
  }

  &.switch {
    &:global(:not(.disabled)) {
      cursor: pointer;

      &:focus-visible,
      &:has(:focus-visible),
      &:hover {
        :global(.fieldInput) {
          box-shadow: 0 0 0 pxToRem(2) var(--bor-pri-mut);
        }
      }
    }
  }

  &:global(.disabled) {
    .content {
      color: var(--tex-def-tie3);

      small {
        color: inherit;
      }
    }
  }

  &.card {
    padding: pxToRem(8);
    border-radius: var(--cor_rad-md);
    background: var(--bac-tie1);
    overflow: hidden;
    box-shadow: var(--elevation-2dp);
    font-weight: 800;
    border: pxToRem(1) solid var(--whi-100);
    position: relative;

    &:not(:has(:global(.multilines))) {
      align-items: center;
    }

    :global(.multilines) {
      h4 {
        margin: 0;
      }
      p {
        @include fontSize(14, 18);
        color: var(--tex-def-tie2);
        font-weight: 400;
        margin: 0;
      }
    }

    small {
      font-weight: 400;
    }

    .inputWrapper {
      display: flex;
      padding: pxToRem(8) pxToRem(4) pxToRem(8) pxToRem(8);
    }

    .radioCheckedCardIcon {
      position: absolute;
      top: pxToRem(8);
      left: pxToRem(8);
      color: var(--for-suc-bas);
      border-radius: var(--cor_rad-ful);
    }

    &:global(.disabled) {
      background: var(--for-neu-dis);
    }

    &:global(.error) {
      box-shadow:
        var(--elevation-2dp),
        inset 0 0 0 pxToRem(2) var(--bor-err-bas);
    }

    &:global(:not(.disabled)) {
      cursor: pointer;

      outline: none;

      &:not(:has(input:checked)):hover {
        box-shadow: 0 0 0 pxToRem(1) var(--bor-neu-bas);
      }

      &:not(:has(input:checked)):focus-visible,
      &:not(:has(input:checked)):has(:focus-visible) {
        background: var(--sta-on_bac-hov);
        border-color: var(--bor-neu-emp);
        box-shadow: 0 0 0 pxToRem(1) var(--bor-neu-emp);
      }

      // For checked cards
      &:has(input:checked) {
        background: var(--for-pri-lig);
        border-color: var(--bor-pri-bas);

        &:hover {
          background: var(--bac-tie1);
          border-color: var(--bor-pri-bas);
          box-shadow: 0 0 0 pxToRem(1) var(--bor-pri-bas);
        }

        &:not(:hover):focus-visible,
        &:not(:hover):has(:focus-visible) {
          @include maskedBackground(var(--sta-sec-hov));
          border-color: var(--bor-pri-bas);
          box-shadow: 0 0 0 pxToRem(1) var(--bor-pri-bas);
        }
      }
    }
  }

  &.white,
  &.sand {
    padding: pxToRem(12) pxToRem(12) pxToRem(12) pxToRem(10);
    border-radius: var(--cor_rad-xs);
    box-shadow: var(--elevation-0dp);

    &:global(.disabled) {
      background: var(--for-neu-dis);
    }

    &:global(.error) {
      box-shadow:
        var(--elevation-0dp),
        inset 0 0 0 pxToRem(2) var(--bor-err-bas);
    }
  }

  &.white {
    background: var(--bac-tie1);
  }

  &.sand {
    background: var(--bac-tie2);
  }
}

.inputWrapper {
  grid-area: 🎛️;
  position: relative;
}

.content {
  grid-area: 💬;
  @include fontSize(17, 24);
  color: var(--tex-def-tie1);
  transition: color ease-in-out 100ms;

  small {
    display: block;
    @include fontSize(15, 20);
    color: var(--tex-def-tie2);
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.loadingIcon {
  width: pxToRem(120);
  height: pxToRem(80);
}

.header {
  display: flex;
  justify-content: center;
  margin-top: pxToRem(40);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Height minus header height (104px) */
  min-height: calc(100vh - pxToRem(104));
}

@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.animationContainer {
  width: 100%;
  max-width: pxToRem(640);
  aspect-ratio: 1155/837;
  margin: auto;
  position: relative;

  svg {
    display: block;
  }
}

.animationPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transition: opacity 200ms;

  &.animationPlaceholderHidden {
    opacity: 0;
  }
}

.contentContainer {
  margin-top: pxToRem(32);

  h1 {
    margin: 0 0 pxToRem(10) 0;
    @include fontSize(26, 32);
    color: var(--tex-def-tie1);
    font-weight: 800;
    text-align: center;
  }

  p {
    @include fontSize(17, 24);
    color: var(--tex-def-tie1);
    font-weight: 400;
    text-align: center;
    margin-bottom: pxToRem(24);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ctaContainer {
  margin-top: pxToRem(40);
  width: 100%;
}

.cta {
  width: 100%;
}

@include md {
  .ctaContainer {
    max-width: pxToRem(176);
    margin: pxToRem(40) auto 0;
  }
}

@include mdp {
  .root {
    margin-top: pxToRem(-40);
  }
}

@include xl {
  .root {
    margin-top: pxToRem(80);
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

.purpleBox {
  background: $color-uiPrimary-050;
  border-radius: var(--cor_rad-xs);
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: pxToRem(20);
}

.email {
  padding: 0 pxToRem(14) pxToRem(16);
  margin-top: pxToRem(-20);
}

@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.root {
  border-radius: var(--cor_rad-lg);
  border: $border-0;
  box-shadow: var(--elevation-0dp);
  min-height: pxToRem(350);
  background-color: var(--bac-tie1);
  overflow: hidden;
  position: relative;

  :global(.tabs-tablistWrapper) {
    background: var(--bac-tie2);
  }

  :global(.tabs-tab) {
    width: 50%;
  }

  :global(.tabs-tab--active) {
    background: var(--bac-tie1);
  }

  :global(.tabs-tab-label) {
    @include fontSize(17, 24);
  }

  :global(.tabs-content) {
    padding: pxToRem(24);
  }

  .recaptchaNotice {
    margin-top: pxToRem(12);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
}

.helpText {
  @include fontSize(14, 18);
  color: var(--tex-def-tie2);
}

.formFields {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
  margin-bottom: pxToRem(16);
}

@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  display: block;
  width: 100%;
  position: relative;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  @include fontSize(17, 24);
  font-family: inherit;
  width: 100%;
  height: pxToRem(64);
  appearance: none;
  box-sizing: border-box;
  display: block;
  border: none;
  padding: pxToRem(8) pxToRem(112) pxToRem(8) pxToRem(16);
  border-radius: var(--cor_rad-xs);
  box-shadow: 0 0 0 pxToRem(1) $color-neutral-100;
  transition: box-shadow ease-in-out 200ms;

  &:placeholder-shown {
    padding-right: pxToRem(60);
  }

  &:hover {
    box-shadow: 0 0 0 pxToRem(2) $color-uiPrimary-900;
  }

  &:focus {
    box-shadow: 0 0 0 pxToRem(4) $color-uiPrimary-900;
    outline: 0;
  }
}

.controls {
  position: absolute;
  right: pxToRem(12);
  top: pxToRem(12);
  display: flex;
  align-items: center;
  justify-content: flex-end;

  :global(.button:disabled) {
    color: $color-uiPrimary-900;
  }
}

.spacer {
  display: block;
  width: 0;
  height: pxToRem(32);
  border-left: $border-0;
  margin: 0 pxToRem(8);
}

.clear:not(.active) {
  display: none;

  + .spacer {
    display: none;
  }
}

.popper {
  background: var(--bac-tie1);
  color: var(--tex-def-tie1);
  padding: pxToRem(16);
  border-radius: var(--cor_rad-xs);
  box-sizing: border-box;
  box-shadow: var(--elevation-2dp);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: $zIndex-modal;
  transition-property: opacity;

  &:global([data-status='open']),
  &:global([data-status='close']) {
    transition-duration: 200ms;
  }

  &:global[data-status='initial'],
  &:global[data-status='close'] {
    opacity: 0;
  }
}

//
// isMobile styles
//
.isMobile {
  .popper {
    background: transparent;
    box-shadow: none;
  }
}

@include md {
  .isMobile {
    .popper {
      background: var(--bac-tie1);
      box-shadow: var(--elevation-2dp);
    }
  }
}

@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  --header-height: #{pxToRem(56)};
  width: 100vw;
  min-height: 100vh;
  background: var(--bac-tie2);
}
.header,
.content {
  @include rootSection(transparent);
}

.header {
  position: relative;
  height: var(--header-height);
  z-index: $zIndex-header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: pxToRem(8);
  padding-bottom: pxToRem(8);
}

.headerLogo {
  display: block;
  height: 100%;
  box-sizing: border-box;
  margin-top: pxToRem(-24);

  img {
    display: block;
    width: pxToRem(162);
    height: pxToRem(70);
    object-fit: contain;
    object-position: center;
    margin: 0 pxToRem(-32);
  }
}

.content {
  padding-bottom: pxToRem(16);
}

@include md {
  .root {
    --header-height: #{pxToRem(64)};
  }
  .header {
    padding-top: pxToRem(12);
    padding-bottom: pxToRem(12);
    background: transparent;
  }
  .headerLogo img {
    margin-right: 0;
    height: pxToRem(72);
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;

.container {
  :global {
    .MuiPaper-root {
      width: 100%;
      max-height: 100%;
      max-width: pxToRem(480);
      height: 100%;
      margin: 0;
      border-radius: 0;
    }

    .MuiDialogTitle-root {
      padding: pxToRem(20);
    }

    .MuiDialog-paperWidthSm {
      max-width: pxToRem(480);
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $color-neutral-100;
    }

    .MuiInputBase-root,
    .MuiFormLabel-root,
    .MuiFormHelperText-root {
      font-family: inherit;
    }
  }

  .contentWrapper {
    padding: pxToRem(20) pxToRem(20) pxToRem(0);
    display: flex;
    flex-direction: column;
    height: 100%;

    :global {
      .MuiDialogContent-root {
        width: 100%;
        padding: pxToRem(0) 0 pxToRem(32);
      }

      .MuiDialogActions-root {
        padding: 0 0 pxToRem(20);
      }
    }
  }
}

@include sm {
  .container {
    :global {
      & .MuiPaper-rounded {
        border-radius: var(--cor_rad-lg);
      }
      & .MuiPaper-root {
        max-height: unset;
        height: unset;
      }
    }
  }
}

@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: pxToRem(4);
}

.title {
  font-size: pxToRem(26);
  line-height: pxToRem(32);
  font-weight: 800;
  color: var(--tex-def-tie1);
}
.closeButton {
  min-width: auto;
  background: none;
  padding: pxToRem(6);
  border: none;
  cursor: pointer;

  img {
    pointer-events: none;
  }
}
